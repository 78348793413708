import {
  Card,
  List,
  Link,
  ListSubheader,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";

import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    small: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      marginLeft: 3,
    },
    mail: {
      width: theme.spacing(4),
      height: theme.spacing(3),
      marginLeft: 3,
    },
    loc: {
      width: "25px",
      height: "32px",
      marginLeft: 7,
      objectFit: "fill",
    },
  })
);

function ContactInfoCard({ item }) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Card style={{ marginBottom: 18 }}>
      <List dense={true} subheader={<ListSubheader color="primary">{t("contact_info")}</ListSubheader>}>
        <ListItem>
          <ListItemAvatar>
            <Avatar
              variant="square"
              src={`/assets/images/PriceInformation/${item.bookable ? "Green" : "Blue"}/08.png`}
              className={classes.loc}
              imgProps={{ style: { objectFit: "fill" } }}
            ></Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={item.address.streetAndHouseNumber}
            secondary={
              <span style={{ color: "#212121" }}>{item.address.areaCode + " " + item.address.area}</span>
            }
          />
        </ListItem>
        {item.phone && item.phone !== "-" && (
          <ListItem>
            <ListItemAvatar>
              <Avatar
                style={{ backgroundColor: "white" }}
                src={`/assets/images/PriceInformation/${item.bookable ? "Green" : "Blue"}/04.png`}
                className={classes.small}
              ></Avatar>
            </ListItemAvatar>
            <ListItemText primary={item.phone} />
          </ListItem>
        )}
        {item.email && item.email !== "-" && (
          <ListItem>
            <ListItemAvatar>
              <Avatar
                variant="square"
                src={`/assets/images/PriceInformation/${item.bookable ? "Green" : "Blue"}/06.png`}
                className={classes.mail}
              ></Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography variant="subtitle2" color="primary">
                  <Link href={`mailto:${item.email}`} onClick={(event: any) => console.log("email")}>
                    {item.email}
                  </Link>
                </Typography>
              }
            />
          </ListItem>
        )}
        {item.homepage && item.homepage !== "-" && (
          <ListItem>
            <ListItemAvatar>
              <Avatar
                variant="square"
                src={`/assets/images/PriceInformation/${item.bookable ? "Green" : "Blue"}/07.png`}
                className={classes.small}
              ></Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography variant="subtitle2" color="primary">
                  <Link
                    href={`${item.homepage}`}
                    target="_blank"
                    onClick={(event: any) => console.log("homepage")}
                  >
                    {item.homepage && item.homepage.split("/")[2]}
                  </Link>
                </Typography>
              }
            />
          </ListItem>
        )}
        {item.imprint && item.imprint !== "-" && (
          <ListItem>
            <ListItemAvatar>
              <Avatar
                variant="square"
                src={`/assets/images/PriceInformation/${item.bookable ? "Green" : "Blue"}/07.png`}
                className={classes.small}
              ></Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={
                <Typography variant="subtitle2" color="primary">
                  <Link
                    href={`${item.imprint}`}
                    target="_blank"
                    onClick={(event: any) => console.log("homepage")}
                  >
                    Impressum
                  </Link>
                </Typography>
              }
            />
          </ListItem>
        )}
      </List>
    </Card>
  );
}

export default ContactInfoCard;
