import axios from "axios";
import { PublicRestApiPath } from "./PublicRestApiPath";
import { PrivateRestApiPath } from "./PrivateRestApiPath";
import { callConfigGet, callConfigPost, callConfigPut, callConfigDelete } from "./RequestOption";

const privatePathPrefix = "/api/private";
const publicPathPrefix = "/api/public/";

// ?-- PUBLIC

export async function getParkingArealById(id?: string) {
  // let filter = id ? "?id=" + id : "";
  let callConfig = await callConfigGet(publicPathPrefix + PublicRestApiPath.parkingAreal + `/${id}`);
  return axios(callConfig);
}

export async function fairMail(name: any, email: any) {
  let filter = name && email ? "?name=" + name + "&email=" + email : "";
  let callConfig = await callConfigPost(publicPathPrefix + PublicRestApiPath.mail + filter);
  return axios(callConfig);
}

export async function payBack(orderid: any, payerid: any) {
  let filter = orderid && payerid ? "?orderid=" + orderid + "&payerid=" + payerid : "";
  let callConfig = await callConfigPost(publicPathPrefix + PublicRestApiPath.payBack + filter);
  return axios(callConfig);
}

export async function getPublicSearch(params?: any) {
  let callConfig = await callConfigGet(publicPathPrefix + PublicRestApiPath.parkingArealSearch, params);
  return axios(callConfig);
}

export async function adminRegister(data?: any) {
  let callConfig = await callConfigPost(publicPathPrefix + PublicRestApiPath.user, data);
  return axios(callConfig);
}

export async function getCommentsOfParkingAreal(id?: string) {
  let callConfig = await callConfigGet(publicPathPrefix + `/parkingAreal/${id}/comments`);
  return axios(callConfig);
}

// comments duplicate check ???
export async function getCommentsParkingAreal(id: any) {
  let callConfig = await callConfigGet(
    publicPathPrefix + PublicRestApiPath.parkingAreal + "/" + id + "/comments"
  );
  return axios(callConfig);
}

export async function getCommentUser(id?: string) {
  let filter = id ? "?id=" + id : "";
  let callConfig = await callConfigGet(
    publicPathPrefix + PublicRestApiPath.parkingArealCommentsUserCouch + filter
  );
  return axios(callConfig);
}

// ?? in use
export async function getPreBookingPlan(
  startDate: string,
  endDate: string,
  parkingArealId: string,
  vehicleType: string
) {
  // let filter = "?start=" + startDate + "&end=" + endDate + "&type=" + type + "&parkingArealId=" + id;
  let filter =
    "?startDate=" +
    startDate +
    "&endDate=" +
    endDate +
    "&parkingArealId=" +
    parkingArealId +
    "&vehicleType=" +
    vehicleType;
  let callConfig = await callConfigGet(publicPathPrefix + PublicRestApiPath.preBookingSvg + filter);
  return axios(callConfig);
}

// ?? in use
export async function verifyPhone(phoneNumber: string, parkingArealId: string, smssend?: boolean) {
  // let filter = "?start=" + startDate + "&end=" + endDate + "&type=" + type + "&parkingArealId=" + id;
  let filter = "?phoneNumber=" + phoneNumber + "&parkingArealId=" + parkingArealId;
  if (smssend) {
    filter = filter + "&smssend=" + smssend;
  }
  let callConfig = await callConfigGet(publicPathPrefix + PublicRestApiPath.preBookingPhoneVerify + filter);
  return axios(callConfig);
}

// ?? in use
export async function createPreBooking(data: any) {
  // let filter = "?start=" + startDate + "&end=" + endDate + "&type=" + type + "&parkingArealId=" + id;
  // let filter = "?phoneNumber=" + phoneNumber;
  let callConfig = await callConfigPost(publicPathPrefix + PublicRestApiPath.preBookingCreate, data);
  return axios(callConfig);
}

// ?? in use
export async function getPriceForPreBooking(data: any) {
  // let filter = "?start=" + startDate + "&end=" + endDate + "&type=" + type + "&parkingArealId=" + id;
  // let filter = "?phoneNumber=" + phoneNumber;
  let callConfig = await callConfigPost(publicPathPrefix + PublicRestApiPath.preBookingPrice, data);
  return axios(callConfig);
}

export async function getWorkSeasonPrice(data: any) {
  const callConfig = await callConfigPost(publicPathPrefix + PublicRestApiPath.workSeasonPrice, data);
  return axios(callConfig);
}

export async function getServiceForPreBooking(parkingArealId: any) {
  let filter = "?parkingArealId=" + parkingArealId;
  let callConfig = await callConfigGet(publicPathPrefix + PublicRestApiPath.preBookingServices + filter);
  return axios(callConfig);
}

export async function setSpotOccupied(data: any) {
  // let filter = "?start=" + startDate + "&end=" + endDate + "&type=" + type + "&parkingArealId=" + id;
  // let filter = "?phoneNumber=" + phoneNumber;
  let callConfig = await callConfigPost(publicPathPrefix + PublicRestApiPath.placeOccupancy, data);
  return axios(callConfig);
}

// ?? in use
export async function preBookingPaymentConfirm(data: any) {
  // let filter = "?start=" + startDate + "&end=" + endDate + "&type=" + type + "&parkingArealId=" + id;
  // let filter = "?phoneNumber=" + phoneNumber;
  let callConfig = await callConfigPost(
    publicPathPrefix + PublicRestApiPath.preBookingPrepaymentConfirm,
    data
  );
  return axios(callConfig);
}

// ?-- PRIVATE

// ?? in use
export async function bookingPaymentConfirm(data: any) {
  // let filter = "?start=" + startDate + "&end=" + endDate + "&type=" + type + "&parkingArealId=" + id;
  // let filter = "?phoneNumber=" + phoneNumber;
  let callConfig = await callConfigPost(
    privatePathPrefix + PrivateRestApiPath.bookingPrepaymentConfirm,
    data
  );
  return axios(callConfig);
}

export async function createTraveller(data?: any) {
  let callConfig = await callConfigPost(privatePathPrefix + PrivateRestApiPath.userTraveller, data);
  return axios(callConfig);
}

export async function deleteUserTraveller(id?: any) {
  let callConfig = await callConfigDelete(privatePathPrefix + PrivateRestApiPath.userTraveller + "/" + id);
  return axios(callConfig);
}

export async function updateTraveller(data?: any) {
  let id = data.id;
  let callConfig = await callConfigPut(privatePathPrefix + PrivateRestApiPath.userTraveller + "/" + id, data);
  return axios(callConfig);
}

export async function updateUserProfile(data?: any) {
  let callConfig = await callConfigPut(privatePathPrefix + PrivateRestApiPath.userProfile, data);
  return axios(callConfig);
}

export async function getVerifyCode(code: string) {
  let filter = code ? "/" + code : "";
  let callConfig = await callConfigGet(privatePathPrefix + "/user/sync/verify" + filter);
  return axios(callConfig);
}

export async function postVerifySync(code: string, bookingId: string) {
  let filter = code && bookingId ? "/" + code + "/" + bookingId : "";
  let callConfig = await callConfigPost(privatePathPrefix + "/user/sync/done/verify" + filter);
  return axios(callConfig);
}

// ?? in use
export async function getParkingSpotsSVG(startDate: string, endDate: string, id: string) {
  // let filter = "?start=" + startDate + "&end=" + endDate + "&type=" + type + "&parkingArealId=" + id;
  let filter = "?start=" + startDate + "&end=" + endDate + "&parkingArealId=" + id;
  let callConfig = await callConfigGet(privatePathPrefix + PrivateRestApiPath.svgBooking + filter);
  return axios(callConfig);
}

export async function getParkingSpotsSVGMobile() {
  let callConfig = await callConfigGet(privatePathPrefix + "booking/reservation/default");
  return axios(callConfig);
}

export async function userCreateBooking(data?: any) {
  let callConfig = await callConfigPost(privatePathPrefix + PrivateRestApiPath.booking, data);
  return axios(callConfig);
}

export async function getUserRole(id?: string) {
  let filter = id ? "?userId=" + id : "";
  let callConfig = await callConfigGet(privatePathPrefix + PrivateRestApiPath.userRole + filter);
  return axios(callConfig);
}

export async function getUserProfile(id?: string) {
  let callConfig = await callConfigGet(privatePathPrefix + PrivateRestApiPath.userProfile);
  console.log(callConfig);
  return axios(callConfig);
}

export async function getUserTravellers() {
  // let filter = id  ? "?userId=" + id : "";
  let callConfig = await callConfigGet(privatePathPrefix + PrivateRestApiPath.userTraveller);
  console.log(callConfig);
  return axios(callConfig);
}

export async function getPrivateParkingArealById(id?: string) {
  let filter = id ? "?id=" + id : "";
  let callConfig = await callConfigGet(privatePathPrefix + PrivateRestApiPath.parkingAreal + filter);
  return axios(callConfig);
}

export async function createCommentUser(data?: any) {
  let callConfig = await callConfigPost(privatePathPrefix + PrivateRestApiPath.commentCouch, data);
  return axios(callConfig);
}

export async function uploadParkingArealImage(file: File, parkingArealId: string) {
  const data = new FormData();
  data.set("file", file);
  data.set("association", "parkingAreal");
  data.set("id", parkingArealId);
  return axios(
    await callConfigPost(privatePathPrefix + PrivateRestApiPath.upload, data, "multipart/form-data")
  );
}

export async function uploadUserImage(file: File) {
  const data = new FormData();
  data.set("file", file);
  data.set("association", "user");
  return axios(
    await callConfigPost(privatePathPrefix + PrivateRestApiPath.upload, data, "multipart/form-data")
  );
}

export async function uploadTravellerImage(file: File, travellerId: string) {
  const data = new FormData();
  data.set("file", file);
  data.set("association", "travel");
  data.set("id", travellerId);
  return axios(
    await callConfigPost(privatePathPrefix + PrivateRestApiPath.upload, data, "multipart/form-data")
  );
}

export async function uploadInfoImage(file: File) {
  const data = new FormData();
  data.set("file", file);
  data.set("association", "info");
  return axios(
    await callConfigPost(privatePathPrefix + PrivateRestApiPath.upload, data, "multipart/form-data")
  );
}

export async function uploadAdminImage(file: File) {
  const data = new FormData();
  data.set("file", file);
  data.set("association", "admin");
  return axios(
    await callConfigPost(privatePathPrefix + PrivateRestApiPath.upload, data, "multipart/form-data")
  );
}

export async function getBookmarks() {
  let callConfig = await callConfigGet(privatePathPrefix + PrivateRestApiPath.userBookmarks);
  return axios(callConfig);
}

export async function deleteBookmarks(folderName: string, ParkingArealId: string) {
  let filter =
    folderName && ParkingArealId ? "?folderName=" + folderName + "&ParkingArealId=" + ParkingArealId : "";
  let callConfig = await callConfigDelete(privatePathPrefix + PrivateRestApiPath.userBookmarks + filter);
  return axios(callConfig);
}

export async function changeBookmarksFolder(
  firstFolderName: string,
  ParkingArealId: string,
  secondFolderName: string
) {
  let filter =
    firstFolderName && ParkingArealId && secondFolderName
      ? "?firstFolderName=" +
        firstFolderName +
        "&ParkingArealId=" +
        ParkingArealId +
        "&secondFolderName=" +
        secondFolderName
      : "";
  let callConfig = await callConfigPost(privatePathPrefix + PrivateRestApiPath.userBookmarks + filter);
  return axios(callConfig);
}

export async function deleteBookmarksFolder(folderName: string) {
  let filter = folderName ? "?folderName=" + folderName : "";
  let callConfig = await callConfigDelete(
    privatePathPrefix + PrivateRestApiPath.userBookmarksFolder + filter
  );
  return axios(callConfig);
}

export async function createBookmarksFolder(folderName: string) {
  let filter = folderName ? "?folderName=" + folderName : "";
  let callConfig = await callConfigPut(privatePathPrefix + PrivateRestApiPath.userBookmarksFolder + filter);
  return axios(callConfig);
}

export async function getCommentOfUser() {
  let callConfig = await callConfigGet(privatePathPrefix + PrivateRestApiPath.userCommentsCouch);
  return axios(callConfig);
}

export async function getBookingOfUser() {
  let callConfig = await callConfigGet(privatePathPrefix + PrivateRestApiPath.booking);
  return axios(callConfig);
}

export async function getReceiptsOfUser() {
  let callConfig = await callConfigGet(privatePathPrefix + PrivateRestApiPath.userReceipt);
  return axios(callConfig);
}

export async function getBookingHistoryOfUser() {
  let callConfig = await callConfigGet(privatePathPrefix + PrivateRestApiPath.bookingHistory);
  return axios(callConfig);
}

export async function bookingCommentUpdate(id: any) {
  let filter = id ? "?id=" + id : "";
  let callConfig = await callConfigPost(privatePathPrefix + PrivateRestApiPath.booking + filter);
  return axios(callConfig);
}

export async function getParkingSpotBookingPricesByDate(
  parkingArealId: any,
  parkingSpotId: any,
  startDate: any,
  endDate: any
) {
  let filter =
    parkingArealId && parkingSpotId && startDate && endDate
      ? "?parkingArealId=" +
        parkingArealId +
        "&parkingSpotId=" +
        parkingSpotId +
        "&startDate=" +
        startDate +
        "&endDate=" +
        endDate
      : "";
  let callConfig = await callConfigGet(privatePathPrefix + PrivateRestApiPath.bookingPrice + filter);
  return axios(callConfig);
}

export async function getUserReceipt(bookingId: any) {
  let callConfig = await callConfigGet(privatePathPrefix + "booking/" + bookingId + "/receipt");
  return axios(callConfig);
}

// ?? in use
export async function getVehicle(id?: any) {
  let callConfig = await callConfigGet(privatePathPrefix + PrivateRestApiPath.userVehicle);
  return axios(callConfig);
}

export async function deleteVehicle(id?: any) {
  let callConfig = await callConfigDelete(privatePathPrefix + PrivateRestApiPath.userVehicle);
  return axios(callConfig);
}

export async function createVehicle(data?: any) {
  let callConfig = await callConfigPost(privatePathPrefix + PrivateRestApiPath.userVehicle, data);
  return axios(callConfig);
}

export async function updateVehicle(data?: any) {
  let callConfig = await callConfigPut(privatePathPrefix + PrivateRestApiPath.userVehicle, data);
  return axios(callConfig);
}

export async function getBookmarksAll() {
  let callConfig = await callConfigGet(privatePathPrefix + PrivateRestApiPath.userBookmarkAll + "?web=true");
  return axios(callConfig);
}

export async function createBookmarkFolder(data?: any) {
  let callConfig = await callConfigPost(privatePathPrefix + PrivateRestApiPath.userBookmarkFolder, data);
  return axios(callConfig);
}

// ?? in use
export async function deleteBookmarkFolder(id: any) {
  let callConfig = await callConfigDelete(
    privatePathPrefix + PrivateRestApiPath.userBookmarkFolder + "/" + id
  );
  return axios(callConfig);
}

export async function deleteBookmark(id: any) {
  let callConfig = await callConfigDelete(privatePathPrefix + PrivateRestApiPath.userBookmark + "/" + id);
  return axios(callConfig);
}

export async function changeBookmarkFolder(id: any, data: any) {
  let callConfig = await callConfigPut(privatePathPrefix + PrivateRestApiPath.userBookmark + "/" + id, data);
  return axios(callConfig);
}

export async function getBookmarkFolderAll() {
  let callConfig = await callConfigGet(privatePathPrefix + PrivateRestApiPath.userBookmarkFolder);
  return axios(callConfig);
}

export async function createBookmark(data?: any) {
  let callConfig = await callConfigPost(privatePathPrefix + PrivateRestApiPath.userBookmark, data);
  return axios(callConfig);
}

export async function getUserFilters() {
  let callConfig = await callConfigGet(privatePathPrefix + PrivateRestApiPath.userFilter);
  return axios(callConfig);
}

export async function updateUserFilters(data?: any) {
  let callConfig = await callConfigPut(privatePathPrefix + PrivateRestApiPath.userFilter, data);
  return axios(callConfig);
}

export async function getPrivateSearch(params?: any) {
  let callConfig = await callConfigGet(privatePathPrefix + PrivateRestApiPath.parkingArealSearch, params);
  return axios(callConfig);
}

export async function getUserOwn(params?: any) {
  let callConfig = await callConfigGet(privatePathPrefix + PrivateRestApiPath.userOwn);
  return axios(callConfig);
}

export async function createComment(data?: any) {
  let callConfig = await callConfigPost(privatePathPrefix + PrivateRestApiPath.userComment, data);
  return axios(callConfig);
}

export async function getCommentsUser() {
  let callConfig = await callConfigGet(privatePathPrefix + PrivateRestApiPath.userComment);
  return axios(callConfig);
}

export async function deleteCommentsUser(id: any) {
  let callConfig = await callConfigDelete(privatePathPrefix + PrivateRestApiPath.userComment + "/id");
  return axios(callConfig);
}
